import { BrandingColors } from './branding-types';

export const defaultColors: BrandingColors = {
  primary: '#18181A',
  onPrimary: '#FFFFFF',
  secondary: '#18181A',
  onSecondary: '#FFFFFF',
  destructive: '#a51010',
  onDestructive: '#FFFFFF',
  accent: '#18181A',
  onAccent: '#FFFFFF',
  widget: '#FFFFFF',
  onWidget: '#18181A',
  background: '#FFFFFF',
  onBackground: '#18181A'
};
