import React, { FunctionComponent } from 'react';
import { useNavigate } from 'react-router-dom';
import { ChevronsRight } from 'lucide-react';
import { ClinicWithPetsResponse } from '@companion-professional/dctypes';
import { Button, ErrorAlert } from '@companion-professional/components';
import { useCreateCheckupForClinic } from '../../hooks/useCreateCheckupForClinic';
import { NoPetsFoundError } from './NoPetsFoundError';
import { NeedHelpContactSupport } from '../../components/NeedHelpContactSupport';
import { IntroLander } from '../../components/IntroLander';

interface InitialInstructionsProps {
  clinic: ClinicWithPetsResponse;
}

// InitialInstructions is a component that displays a welcome message and a button to create and start the checkup.
// Because a link can have one or multiple pets; this component displays different instructions for each case.
export const InitialInstructions: FunctionComponent<InitialInstructionsProps> = ({ clinic }) => {
  const navigate = useNavigate();
  const { isPending, error, createCheckup } = useCreateCheckupForClinic();

  if (error) {
    return (
      <div className="my-10">
        <ErrorAlert title="Error" message={error.message} />
      </div>
    );
  }

  if ((clinic?.pets || []).length === 0) {
    return <NoPetsFoundError />;
  }

  return (
    <IntroLander clinicName={clinic.clinic_name} clinicLogoUrl={clinic.brand_logo_url}>
      {(clinic?.pets || []).map((pet) => (
        <Button
          key={pet.clinic_pet_id}
          variant="primary"
          size="large"
          className="w-full text-base sm:text-lg"
          disabled={isPending}
          onClick={async () => {
            const checkupId = await createCheckup({ clinicId: clinic.clinic_id, clinicPetId: pet.clinic_pet_id });
            navigate(`/${checkupId}/checkup`);
          }}
        >
          <div className="flex w-full flex-row items-center justify-center">
            <div className="truncate">{pet.pet_name}&nbsp;</div>
            <div>is Ready</div>
            <div>
              <ChevronsRight className="h-6 w-6 sm:h-8 sm:w-8" />
            </div>
          </div>
        </Button>
      ))}
      <NeedHelpContactSupport />
    </IntroLander>
  );
};
