import { useQuery } from '@tanstack/react-query';
import { ClinicWithPetsResponse } from '@companion-professional/dctypes';
import { getClinicBySlugAndMsgId, getClinicBySlugAndPetIds } from '../lib/api';

type ClinicQueryParams = {
  clinicPetIds?: string | null;
  clinicMessageId?: string | null;
};

// useClinic is a hook that fetches a PIMS enabled clinic by its slug and either the clinic's pet ID(s) or a message ID
// (like the marketing message sent by Virtual Recall). If both clinicPetIds and clinicMessageId are provided, it will
// use clinicPetIds.  It returns the clinic and pet information (e.g. names).
export const useClinic = (
  clinicSlug: string,
  clinicQueryParams: ClinicQueryParams,
  extraParams: Record<string, string> = {}
) => {
  const queryKey = ['clinic-slug', clinicSlug];
  if (clinicQueryParams.clinicPetIds) {
    queryKey.push(`clinic-pet-${clinicQueryParams.clinicPetIds}`);
  }
  if (clinicQueryParams.clinicMessageId) {
    queryKey.push(`clinic-msg-${clinicQueryParams.clinicMessageId}`);
  }

  const {
    isPending,
    error,
    data: clinic
  } = useQuery<ClinicWithPetsResponse>({
    queryKey,
    queryFn: () => {
      if (clinicQueryParams.clinicPetIds) {
        return getClinicBySlugAndPetIds(clinicSlug, clinicQueryParams.clinicPetIds);
      }

      if (clinicQueryParams.clinicMessageId) {
        return getClinicBySlugAndMsgId(clinicSlug, clinicQueryParams.clinicMessageId, extraParams);
      }

      throw new Error('Invalid clinic query params');
    },
    staleTime: 1000 * 15 // 15 seconds
  });

  return {
    isPending,
    error,
    clinic
  };
};
