import React, { FunctionComponent } from 'react';
import { getBrowserName } from '../../lib/ua';
import { HelpLayout } from '../../components/HelpLayout';

export const AndroidHelp: FunctionComponent = () => {
  const browserName = getBrowserName();

  return (
    <HelpLayout>
      <p className="my-2">
        If you are having issues with accessing your camera, please try the following steps to enable camera access on
        your Android device:
      </p>
      <ol className="standard-list">
        <li>
          Go to the home page and tap <strong>Settings</strong> (or search for the <strong>settings</strong> app.
        </li>
        <li>
          Tap <strong>Apps</strong>.
        </li>
        <li>
          Tap<strong>All Apps</strong> (if present).
        </li>
        <li>
          Tap the icon for the web browser you are using.{' '}
          {browserName ? (
            <span>
              (it looks like you are using <strong>{browserName}</strong>)
            </span>
          ) : null}
        </li>
        <li>
          Tap on <strong>Camera</strong>.
        </li>
        <li>
          Make sure that the permission is set to <strong>Allow only while using the app</strong> or{' '}
          <strong>Ask every time</strong>.
        </li>
      </ol>
      <p className="my-2">
        Once the permissions have been updated, you can return to the checkup page by clicking the button below.
      </p>
      <p className="my-2 italic">
        NOTE: You may need to refresh the page after updating your permissions. If you see a pop-up acting for
        permission to use the camera, make sure to choose the <strong>Allow</strong> option.
      </p>
    </HelpLayout>
  );
};
