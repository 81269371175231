import { useMutation } from '@tanstack/react-query';
import { createCheckupForPetId, createCheckupForClinicPetId } from '../lib/api';

type CreateCheckupValues = {
  clinicId: string;
  petId?: string;
  clinicPetId?: string;
};

// useCreateCheckupForClinic is a hook that creates a new checkup for a clinic and a Companion Vision petId or the
// clinic pet's ID (clinicPetId).  Either the petId or clinicPetId must be provided.  If both are provided, the petId
// will be used.
export const useCreateCheckupForClinic = () => {
  const {
    mutateAsync: createCheckup,
    isPending,
    error
  } = useMutation({
    mutationFn: async ({ clinicId, clinicPetId, petId }: CreateCheckupValues) => {
      if (petId) {
        return createCheckupForPetId(clinicId, petId);
      }
      if (clinicPetId) {
        return createCheckupForClinicPetId(clinicId, clinicPetId);
      }

      return Promise.reject(new Error('Either petId or clinicPetId must be provided.'));
    }
  });

  return {
    createCheckup,
    isPending,
    error
  };
};
