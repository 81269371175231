import * as React from 'react';
import { cn } from '@companion-professional/webutils';

interface TableRowProps extends React.HTMLAttributes<HTMLTableRowElement> {
  // disableRowHoverEffect, if true, this will turn off the enable hover effects (background change) and cursor pointer.
  disableRowHoverEffect?: boolean;
}

export const TableRow = React.forwardRef<HTMLTableRowElement, TableRowProps>(
  ({ className, onClick, disableRowHoverEffect, ...props }, ref) => (
    <tr
      ref={ref}
      className={cn('border-b', 'transition-colors', 'data-[state=selected]:bg-muted', className, {
        'hover:bg-on-widget cursor-pointer hover:text-widget hover:opacity-75': !disableRowHoverEffect
      })}
      {...props}
    />
  )
);
TableRow.displayName = 'TableRow';
