import React, { FunctionComponent } from 'react';

interface TapButtonProps {
  onClick?: () => void;
  children?: React.ReactNode;
}

// TapButton is a stylized div that is used for the tap actions.
export const TapButton: FunctionComponent<TapButtonProps> = ({ children, onClick }) => {
  return (
    <div
      className={
        'my-1 flex w-full max-w-lg flex-row items-center justify-center gap-1 rounded-full bg-primary px-6 py-4 ' +
        'text-on-primary cursor-pointer text-md font-semibold hover:opacity-70 sm:my-2 sm:text-base'
      }
      onClick={onClick}
    >
      {children}
    </div>
  );
};
