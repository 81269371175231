import { useMutation } from '@tanstack/react-query';
import { createCheckupUsingExistingCheckup } from '../lib/api';

type CreateCheckupUsingExistingCheckupValues = {
  clinicId: string;
  existingCheckupId: string;
};

// useCreateCheckupUsingExistingCheckup is a hook that creates a new checkup using an existing checkup request.
export const useCreateCheckupUsingExistingCheckup = () => {
  const {
    mutateAsync: createCheckupWithExistingCheckup,
    isPending,
    error
  } = useMutation({
    mutationFn: async ({ clinicId, existingCheckupId }: CreateCheckupUsingExistingCheckupValues) =>
      createCheckupUsingExistingCheckup(clinicId, existingCheckupId)
  });

  return {
    createCheckupWithExistingCheckup,
    isPending,
    error
  };
};
