import React, { FunctionComponent } from 'react';
import { getBrowserName } from '../../lib/ua';
import { HelpLayout } from '../../components/HelpLayout';

export const IOSHelp: FunctionComponent = () => {
  const browserName = getBrowserName();

  return (
    <HelpLayout>
      <p className="my-2">
        If you are having issues with accessing your camera, please try the following steps to enable camera access on
        your iOS device:
      </p>
      {browserName === 'Mobile Safari' ? (
        <ol className="standard-list">
          <li>
            Go to your home screen and tap <strong>Settings</strong>.
          </li>
          <li>
            Scroll down and tap <strong>Safari</strong>.
          </li>
          <li>
            Scroll down and tap <strong>Camera</strong>.
          </li>
          <li>
            Make sure the setting is set to <strong>Ask</strong> or <strong>Allow</strong>.
          </li>
        </ol>
      ) : (
        <ol className="standard-list">
          <li>
            Go to your home screen and tap <strong>Settings</strong>.
          </li>
          <li>
            Scroll down and tap <strong>Privacy &amp; Security</strong>.
          </li>
          <li>
            Tap <strong>Camera</strong>.
          </li>
          <li>
            Find the browser you are using and toggle the permissions to the on position.{' '}
            {browserName ? (
              <span>
                (it looks like you are using <strong>{browserName}</strong>)
              </span>
            ) : null}
          </li>
        </ol>
      )}

      <p className="my-2">
        Once the permissions have been updated, you can return to the checkup page by clicking the button below.
      </p>
      <p className="my-2 italic">
        NOTE: You may need to refresh the page after updating your permissions. If you see a pop-up acting for
        permission to use the camera, make sure to choose the <strong>Allow</strong> option.
      </p>
    </HelpLayout>
  );
};
