import { getContrastColor } from '@companion-professional/webutils';
import { BrandingColors } from './branding-types';
import { defaultColors } from './defaultColors';

// setColors will set the global CSS variables to the colors provided.
export const setColors = (colors: BrandingColors) => {
  document.documentElement.style.setProperty('--primary-color', colors.primary);
  document.documentElement.style.setProperty('--on-primary-color', colors.onPrimary);
  document.documentElement.style.setProperty('--secondary-color', colors.secondary);
  document.documentElement.style.setProperty('--on-secondary-color', colors.onSecondary);
  document.documentElement.style.setProperty('--destructive-color', colors.destructive);
  document.documentElement.style.setProperty('--on-destructive-color', colors.onDestructive);
  document.documentElement.style.setProperty('--accent-color', colors.accent);
  document.documentElement.style.setProperty('--on-accent-color', colors.onAccent);
  document.documentElement.style.setProperty('--background-color', colors.background);
  document.documentElement.style.setProperty('--on-background-color', colors.onBackground);
  document.documentElement.style.setProperty('--widget-color', colors.widget);
  document.documentElement.style.setProperty('--on-widget-color', colors.onWidget);
};

// setLastLoadedColors will set the colors to the last loaded colors from local storage.  If there are no last loaded
// colors, then it will set the colors to the default colors.
export const setLastLoadedColors = () => {
  try {
    const colorsJson = window.localStorage.getItem('brandColors');
    if (!colorsJson) {
      setColors(defaultColors);
      return;
    }
    const colors = JSON.parse(colorsJson) as BrandingColors;
    setColors(colors);
    return;
  } catch (e) {
    window.localStorage.removeItem('brandColors');
  }
  setColors(defaultColors);
};

// setColorsUsingBrandColor will set the colors to the brand color provided.  It will also set the onPrimary and
// onSecondary colors to the contrast color of the brand color.
export const setColorsUsingBrandColor = (brandColor: string) => {
  const colors = defaultColors;
  colors.primary = brandColor;
  colors.onPrimary = getContrastColor(brandColor);
  colors.secondary = brandColor;
  colors.onSecondary = colors.onPrimary;
  window.localStorage.setItem('brandColors', JSON.stringify(colors));
  setColors(colors);
};
