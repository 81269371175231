import React, { FunctionComponent } from 'react';
import { Loader2 } from 'lucide-react';
import { ErrorAlert } from '../ErrorAlert';
import { cn, getErrorMessageFromAxiosError } from '@companion-professional/webutils';

const loaderSizes = {
  small: 'h-12 w-12',
  medium: 'h-16 w-16',
  large: 'h-32 w-32'
};

interface QueryLoaderWrapperProps {
  // isPending is a boolean that indicates whether the query is pending or not.  If it is pending, a loading spinner
  // will be shown.
  isPending?: boolean;

  // error is an Error object that indicates that there was an error with the query.  If there is an error, an error
  // message will be shown.
  error?: Error | null;

  // errorHeading is the heading (the top, bold section) that will be shown in the error message.  Defaults to "Error".
  errorHeading?: string;

  // children is the content that will be shown if there is no error and the query is not pending.
  children?: React.ReactNode;

  // showChildrenOnError is a prop that can be used to show the children of this component even if there is an error.
  // Defaults to false.
  showChildrenOnError?: boolean;

  // size is the size of the spinning loader icon.  Defaults to "large".
  size?: keyof typeof loaderSizes;
}

// QueryLoaderWrapper is a component that takes output (isPending and error) from a react-query hook and displays a
// loading spinner, error message or the children of the component.
export const QueryLoaderWrapper: FunctionComponent<QueryLoaderWrapperProps> = ({
  isPending = false,
  error = null,
  showChildrenOnError = false,
  size = 'large',
  errorHeading = 'Error',
  children
}) => {
  if (isPending) {
    return (
      <div className="flex flex-1 items-center justify-center">
        <Loader2 className={cn('animate-spin text-secondary', loaderSizes[size])} />
      </div>
    );
  }

  if (error) {
    const message = getErrorMessageFromAxiosError(error);
    return (
      <div className="my-2 flex flex-1 flex-col items-center justify-center">
        <ErrorAlert title={errorHeading} message={message} />
        {showChildrenOnError ? children : null}
      </div>
    );
  }

  return children;
};
