import * as React from 'react';
import * as PopoverPrimitive from '@radix-ui/react-popover';
import { cn } from '@companion-professional/webutils';

// Popover is a component that displays a floating panel of content relative to a target.  This can be used for simple
// input forms like a date picker or a more complex component like a menu.  The Popover component is a wrapper around
// the PopoverPrimitive.Root component from the @radix-ui/react-popover package.
//
// The Popover implementation looks like this:
// <Popover>
//   <PopoverTrigger>...</PopoverTrigger>
//   <PopoverContent>...</PopoverContent>
// </Popover>
export const Popover = PopoverPrimitive.Root;
export const PopoverTrigger = PopoverPrimitive.Trigger;

export const PopoverContent = React.forwardRef<
  React.ElementRef<typeof PopoverPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof PopoverPrimitive.Content>
>(({ className, align = 'center', sideOffset = 4, ...props }, ref) => (
  <PopoverPrimitive.Portal>
    <PopoverPrimitive.Content
      ref={ref}
      align={align}
      sideOffset={sideOffset}
      className={cn(
        'data-[state=open]:animate-in data-[state=closed]:animate-out bg-widget text-on-widget ' +
          'data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95' +
          'data-[state=open]:zoom-in-95 data-[side=bottom]:slide-in-from-top-2' +
          'data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2' +
          'data-[side=top]:slide-in-from-bottom-2 z-50 w-72 rounded-md border p-4 shadow-md outline-none',
        className
      )}
      {...props}
    />
  </PopoverPrimitive.Portal>
));
PopoverContent.displayName = PopoverPrimitive.Content.displayName;
