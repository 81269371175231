// This is a central place to store configuration details for the application.  Currently, these are hardcoded, but in
// the future we could these could be dependent on the environment that the application is running in.

// API URLS
const API_BASE_URL = '/api/v1';
export const CHECKUP_REQUEST_URL = `${API_BASE_URL}/checkup_request`;
export const GENERATE_UPLOAD_URL = `${API_BASE_URL}/upload_url`;

// New Checkup Route
export const CREATE_CHECKUP_URL = `${API_BASE_URL}/checkup_request`;

// PIMS Lander Lookup Routes
const PIMS_BASE_URL = `${API_BASE_URL}/pims`;
export const PIMS_CLINIC_BY_SLUG_AND_PETS_URL = `${PIMS_BASE_URL}/clinic_by_slug_pets`;
export const PIMS_CLINIC_BY_SLUG_AND_MESSAGE_URL = `${PIMS_BASE_URL}/clinic_by_slug_message`;

// Support Contact Information
export const CONTACT_EMAIL = 'cv-support@companionprofessional.com';
export const CONTACT_PHONE = '+18774473647';
