import React, { FunctionComponent, useEffect } from 'react';
import { CheckupRequestInfo } from '@companion-professional/dctypes';
import { trackEvent } from '../../lib/matomo';
import { IntroLander } from '../../components/IntroLander';
import { BottomButton } from '../../components/BottomButton';
import { ChevronsRight } from 'lucide-react';
import { NeedHelpContactSupport } from '../../components/NeedHelpContactSupport';
import { useNavigate } from 'react-router-dom';

interface CheckupLanderProps {
  checkupRequest: CheckupRequestInfo;
}

// CheckupLander is the first page that is shown to the Pet Parent when they click on the link to the checkup.  It
// contains a greeting and action button(s) for starting the checkup.
export const CheckupLander: FunctionComponent<CheckupLanderProps> = ({ checkupRequest }) => {
  const navigate = useNavigate();

  useEffect(() => {
    trackEvent('checkup', 'checkup_flow_change', 'checkup_lander');
  }, [checkupRequest?.pet_name]);

  return (
    <IntroLander clinicName={checkupRequest?.clinic_name} dogName={checkupRequest.pet_name}>
      <BottomButton
        onClick={() => {
          navigate('./checkup');
        }}
      >
        Get Started <ChevronsRight size="24" className="ml-1" />
      </BottomButton>
      <NeedHelpContactSupport className="text-center" />
    </IntroLander>
  );
};
