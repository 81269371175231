import React, { FunctionComponent, useEffect } from 'react';
import * as Sentry from '@sentry/browser';
import { ClinicWithPetsResponse } from '@companion-professional/dctypes';
import { setColorsUsingBrandColor, setLastLoadedColors } from '@companion-professional/branding';
import { ErrorAlert } from '@companion-professional/components';
import { InitialInstructions } from './InitialInstructions';
import { isValidHexColor } from '@companion-professional/webutils';

interface ClinicLanderProps {
  clinic: ClinicWithPetsResponse;
}

// ClinicLander is the main component for the Clinic Lander section of the app.  It will display the
// ClinicLanderPetsFound component if we were able to find pets for the clinic, otherwise it will display the
// ClinicLanderNoLookups component.
export const ClinicLander: FunctionComponent<ClinicLanderProps> = ({ clinic }) => {
  useEffect(() => {
    Sentry.setContext('clinic_lander', {
      clinic_id: clinic.clinic_id,
      clinic_name: clinic.clinic_name,
      clinic_slug: clinic.slug,
      clinic_pets: clinic.pets
    });

    // If we have a brand color for the clinic (and it's valid), then use it.  Otherwise, use the default colors.
    if (isValidHexColor(clinic.brand_color)) {
      setColorsUsingBrandColor(clinic.brand_color);
    } else {
      setLastLoadedColors();
    }
  }, [clinic]);

  if (clinic?.pets && clinic.pets.length > 0) {
    return <InitialInstructions clinic={clinic} />;
  }

  // Display an error message if we couldn't find any pets for the clinic.  In the future we might want to replace this
  // with a generic clinic lander the supports a single clinic url where the user enters information about their pet.
  // This is the "QR code business card" use case.
  return (
    <div className="flex h-full max-w-lg flex-1 flex-col justify-center gap-4 px-2">
      <ErrorAlert title="No Pets Found" message="We couldn't find any pets for this clinic." />
    </div>
  );
};
