import axios, { AxiosResponse, isAxiosError } from 'axios';
import {
  CheckupRequestInfo,
  CreateCheckupResponse,
  ClinicWithPetsResponse,
  CreateCheckupRequest,
  ItemUploadSignedUrl
} from '@companion-professional/dctypes';
import {
  CHECKUP_REQUEST_URL,
  GENERATE_UPLOAD_URL,
  PIMS_CLINIC_BY_SLUG_AND_PETS_URL,
  PIMS_CLINIC_BY_SLUG_AND_MESSAGE_URL,
  CREATE_CHECKUP_URL
} from './config';

type CheckupCompleteResponse = {
  checkupComplete: boolean;
};

// This file contains all the API HTTP requests for the application.

// getCheckupRequest is a function that fetches a single checkup request.
export const getCheckupRequest = async (
  checkupRequestId: string
): Promise<CheckupRequestInfo | CheckupCompleteResponse> => {
  try {
    const response = await axios.get<CheckupRequestInfo>(`${CHECKUP_REQUEST_URL}/${checkupRequestId}`);
    return response.data;
  } catch (e) {
    if (isAxiosError(e) && e?.response?.status === 409) {
      // A 409 status code means that the checkup is complete.
      return {
        checkupComplete: true
      };
    }
    throw e;
  }
};

// getItemUploadUrl is a function that fetches a signed URL for uploading a video to MUX
export const getItemUploadUrl = async (itemId: string): Promise<ItemUploadSignedUrl> => {
  const response = await axios.post<ItemUploadSignedUrl>(`${GENERATE_UPLOAD_URL}/${itemId}`);
  return response.data;
};

// getClinicBySlugAndPetIds is a function that fetches a clinic by its slug and petIds.  It returns the clinic
// and pet information (e.g. names).
export const getClinicBySlugAndPetIds = async (
  clinicSlug: string,
  clinicPetIds: string
): Promise<ClinicWithPetsResponse> => {
  const queryParams: Record<string, unknown> = {};
  queryParams['pet_ids'] = clinicPetIds;
  const response = await axios.get<ClinicWithPetsResponse>(`${PIMS_CLINIC_BY_SLUG_AND_PETS_URL}/${clinicSlug}`, {
    params: queryParams
  });
  return response.data;
};

// getClinicBySlugAndMessageId is a function that fetches a clinic by its slug and messageId.  The message id comes
// from a message sent to a pet owner via a marketing PIMs system (like Virtual Recall). It returns the clinic and
// pet information (e.g. names).  In some cases, extraParams are passed in to the lander (like the msg_secret in the
// case of Virtual Recall).  These parameters can be provided in the extraParams object (defaults to empty).
export const getClinicBySlugAndMsgId = async (
  clinicSlug: string,
  clinicMsgId: string,
  extraParams: Record<string, string> = {}
): Promise<ClinicWithPetsResponse> => {
  const queryParams: Record<string, unknown> = { ...extraParams };
  queryParams['msg_id'] = clinicMsgId;
  const response = await axios.get<ClinicWithPetsResponse>(`${PIMS_CLINIC_BY_SLUG_AND_MESSAGE_URL}/${clinicSlug}`, {
    params: queryParams
  });
  return response.data;
};

// createCheckupForClinicPetId is a function that creates a checkup for a clinic (clinicId) and that clinic's pet ID
// (clinicPetId).
export const createCheckupForClinicPetId = async (clinicId: string, clinicPetId: string): Promise<string> => {
  const response = await axios.post<CreateCheckupResponse, AxiosResponse<CreateCheckupResponse>, CreateCheckupRequest>(
    CREATE_CHECKUP_URL,
    {
      clinic_id: clinicId,
      clinic_pet_id: clinicPetId
    }
  );
  return response.data.id;
};

// createCheckupForPetId is a function that creates a checkup for a clinicId and the Companion Vision petId.
export const createCheckupForPetId = async (clinicId: string, petId: string): Promise<string> => {
  const response = await axios.post<CreateCheckupResponse, AxiosResponse<CreateCheckupResponse>, CreateCheckupRequest>(
    CREATE_CHECKUP_URL,
    {
      clinic_id: clinicId,
      pet_id: petId
    }
  );
  return response.data.id;
};

// createCheckupUsingExistingCheckupRequest is a function that creates a checkup using an existing checkup request.
// Returns the checkup ID that can in turn be used to navigate to the new checkup.
export const createCheckupUsingExistingCheckup = async (
  clinicId: string,
  existingCheckupId: string
): Promise<string> => {
  const response = await axios.post<CreateCheckupResponse, AxiosResponse<CreateCheckupResponse>, CreateCheckupRequest>(
    CREATE_CHECKUP_URL,
    {
      clinic_id: clinicId,
      existing_checkup_id: existingCheckupId
    }
  );
  return response.data.id;
};
