import React, { FunctionComponent } from 'react';
import { Button } from '../Button';
import { CheckCircle, Trash2 } from 'lucide-react';
import { CameraCaptureState, CameraCaptureStates } from './inBrowserCameraTypes';

interface PreviewControlsProps {
  cameraCaptureState: CameraCaptureState;
  submitRecording: () => void;
  startOver: () => void;
  className?: string;
}

// PreviewControls is a component that displays the "Use Video" and "Start Over" buttons when the user is reviewing
// their video recording.  The "Use Video" button submits the recording and the "Start Over" button allows the user to
// start over.  The buttons are displayed in a semi-transparent pill box towards the top of the screen because I
// didn't want to cover the video player controls.
export const PreviewControls: FunctionComponent<PreviewControlsProps> = ({
  cameraCaptureState,
  submitRecording,
  startOver,
  className
}) => {
  if (cameraCaptureState !== CameraCaptureStates.REVIEWING) {
    return null;
  }

  return (
    <div className={className}>
      <div className="flex items-center justify-center gap-3 rounded-full bg-black px-3 py-2">
        <Button type="button" onClick={submitRecording} className="hover:bg-primary">
          <CheckCircle size={20} className="mr-2" />
          Use Video
        </Button>
        <Button type="button" size="icon" variant="destructive" className="hover:opacity-70" onClick={startOver}>
          <Trash2 size={20} />
        </Button>
      </div>
    </div>
  );
};
