import React, { FunctionComponent, useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { withLDProvider } from 'launchdarkly-react-client-sdk';
import { cn, getEnvironmentFromLocation } from '@companion-professional/webutils';
import { Home } from './pages/Home';
import { GenerateError } from './components/GenerateError';
import { CheckupLoader } from './CheckupLoader';
import { ClinicLanderLoader } from './ClinicLanderLoader';
import { useCameraPreviewEnabled } from './state/camera';
import { getMatomoUrl } from './lib/matomo';

declare const __LAUNCH_DARKLY_CLIENT_ID__: string;

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

export const App: FunctionComponent = () => {
  const cameraPreviewEnabled = useCameraPreviewEnabled();

  useEffect(() => {
    const env = getEnvironmentFromLocation('companionpro.biz', 'checkup');

    // Don't load Matomo in development or unknown environments.
    if (['development', 'unknown'].includes(env)) {
      console.log('Not loading Matomo in', env);
      return;
    }

    const matomoUrl = getMatomoUrl(env);

    // If the Matomo URL is not found, log an error and return.
    if (!matomoUrl) {
      console.error('No Matomo URL found for environment', env);
      return;
    }

    const _mtm = (window._mtm = window._mtm || []);
    _mtm.push({ 'mtm.startTime': new Date().getTime(), event: 'mtm.Start' });
    const d = document,
      g = d.createElement('script'),
      s = d.getElementsByTagName('script')[0];
    g.async = true;
    g.src = matomoUrl;
    if (s?.parentNode?.insertBefore) {
      s.parentNode.insertBefore(g, s);
    }
  }, []);

  return (
    <div
      className={cn(
        // NOTE: overflow-x-hidden is necessary to prevent the page from doing a large horizontal scroll when the
        // instructions video player is active.  Not sure why this happens, but hiding the overflow-x fixes it.
        'flex max-h-[60rem] w-full flex-1 flex-col items-center justify-between overflow-y-auto overflow-x-hidden',
        {
          'bg-brand-background': !cameraPreviewEnabled,
          // This is a hack to make the camera preview take up the full screen and push the header (tabs) off the screen.
          'landscape:h-[101vh]': cameraPreviewEnabled
        }
      )}
    >
      <SentryRoutes>
        <Route index element={<Home />} />
        <Route path="/generate-error" element={<GenerateError />} />
        <Route path="/c/:clinicSlug" element={<ClinicLanderLoader />} />
        <Route path="/:checkupId/*" element={<CheckupLoader />} />
      </SentryRoutes>
    </div>
  );
};

// AppWithLDProvider is a wrapper around the App component that provides the LaunchDarkly context. This is required for
// LaunchDarkly to work.
export const AppWithLDProvider = withLDProvider({
  clientSideID: __LAUNCH_DARKLY_CLIENT_ID__
})(App);
