import * as React from 'react';
import { Command as CommandPrimitive } from 'cmdk';
import { cn } from '@companion-professional/webutils';

// CommandItem is used to display a command (a selectable item) in a <CommandList />.
export const CommandItem = React.forwardRef<
  React.ElementRef<typeof CommandPrimitive.Item>,
  React.ComponentPropsWithoutRef<typeof CommandPrimitive.Item>
>(({ className, ...props }, ref) => (
  <CommandPrimitive.Item
    ref={ref}
    className={cn(
      'aria-selected:text-on-primary relative flex cursor-default select-none items-center rounded-sm px-2 py-1.5 ' +
        'hover:text-on-accent text-sm outline-none hover:bg-primary aria-selected:bg-primary data-[disabled]:pointer-events-none' +
        'data-[disabled]:opacity-50',
      className
    )}
    {...props}
  />
));

CommandItem.displayName = CommandPrimitive.Item.displayName;
