import * as React from 'react';
import * as SelectPrimitive from '@radix-ui/react-select';
import { cn } from '@companion-professional/webutils';
import { Check } from 'lucide-react';

// SelectItem is a component that displays a selectable item in a Select component.  This is equivalent to the <item />
// HTML element.
export const SelectItem = React.forwardRef<
  React.ElementRef<typeof SelectPrimitive.Item>,
  React.ComponentPropsWithoutRef<typeof SelectPrimitive.Item>
>(({ className, children, ...props }, ref) => (
  <SelectPrimitive.Item
    ref={ref}
    className={cn(
      [
        'focus:bg-primary',
        'relative',
        'flex',
        'w-full',
        'cursor-pointer',
        'select-none',
        'items-center',
        'rounded-sm',
        'py-1.5',
        'pl-8',
        'pr-2',
        'text-sm',
        'outline-none',
        'focus:text-on-primary',
        'data-[disabled]:pointer-events-none',
        'data-[disabled]:opacity-50'
      ],
      className
    )}
    {...props}
  >
    <span className="absolute left-2 flex h-3.5 w-3.5 items-center justify-center">
      <SelectPrimitive.ItemIndicator>
        <Check className="h-4 w-4" />
      </SelectPrimitive.ItemIndicator>
    </span>

    <SelectPrimitive.ItemText>{children}</SelectPrimitive.ItemText>
  </SelectPrimitive.Item>
));
SelectItem.displayName = SelectPrimitive.Item.displayName;
