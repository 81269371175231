type RGBColor = {
  r: number;
  g: number;
  b: number;
};

export const isValidHexColor = (hex?: string): boolean => {
  if (!hex) {
    return false;
  }

  return /^#?([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/.test(hex);
};

export const hexToRgb = (hex: string): RGBColor => {
  if (!isValidHexColor(hex)) {
    throw new Error('Invalid hex color');
  }

  // Remove # if present
  hex = hex.replace('#', '');

  // Convert hex to decimal values
  const r = parseInt(hex.substring(0, 2), 16);
  const g = parseInt(hex.substring(2, 4), 16);
  const b = parseInt(hex.substring(4, 6), 16);

  return { r, g, b };
};

export const getContrastColor = (hex: string, lightColor = '#ffffff', darkColor = '#000000') => {
  const rgb = hexToRgb(hex);

  // Calculate luminance
  const luminance = 0.2126 * rgb.r + 0.7152 * rgb.g + 0.0722 * rgb.b;

  // Return dark color for light backgrounds and light color for dark backgrounds
  return luminance > 128 ? darkColor : lightColor;
};
