import React, { FunctionComponent, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { ErrorAlert, QueryLoaderWrapper } from '@companion-professional/components';
import { setLastLoadedColors } from '@companion-professional/branding';
import { useCheckupRequest } from './hooks/useCheckupRequest';
import { CheckupRoutes } from './CheckupRoutes';
import { CheckupComplete } from './pages/CheckupComplete';

export type CheckupUrlParams = {
  // checkupId comes from the URL path. e.g. https://https://checkup.companionpro.biz/:checkupId
  checkupId: string;
};

// CheckupLoader loads the checkup based on the checkupId from the URL.  If the checkup is found, it will display the
// checkup routes.
export const CheckupLoader: FunctionComponent = () => {
  const { checkupId } = useParams<CheckupUrlParams>();

  useEffect(() => {
    setLastLoadedColors();
  }, []);

  if (!checkupId || checkupId === '') {
    // TODO: Add handling for no checkupId
    return <ErrorAlert title="Invalid Checkup" message="We were not able to find a checkup ID in the request." />;
  }

  const { isPending, error, checkupRequest, checkupComplete } = useCheckupRequest(checkupId);

  // Complete checkups should not be displayed
  if (checkupComplete) {
    return <CheckupComplete message="A video has been uploaded for this checkup." checkupRequest={checkupRequest} />;
  }

  if (!isPending && !error && checkupRequest?.requests.length !== 1) {
    return (
      <div className="mt-10">
        <ErrorAlert
          title="Error"
          message={`We only support one video request for a checkup.
        This Checkup has ${checkupRequest?.requests.length} asset requests.`}
        />
      </div>
    );
  }

  if (!isPending && !checkupRequest) {
    return (
      <div className="mt-10">
        <ErrorAlert title="Checkup Not Found" message="We were not able to find a checkup with the provided ID." />
      </div>
    );
  }

  return (
    <QueryLoaderWrapper isPending={isPending} error={error}>
      {checkupRequest && !error ? <CheckupRoutes checkupRequest={checkupRequest} /> : null}
    </QueryLoaderWrapper>
  );
};
