import React, { FunctionComponent } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { ErrorAlert, QueryLoaderWrapper } from '@companion-professional/components';
import { ClinicLander } from './pages/ClinicLander';
import { useClinic } from './hooks/useClinic';
const ClinicLanderSearchParams = {
  // clinicPetId is the clinic's pet id (or ids separated by commas) that is passed in the p query param.
  clinicPetId: 'p',

  // clinicMessageId is the clinic's message id that is passed in the m query param.
  clinicMessageId: 'msg_id'
};

export type ClinicLanderCheckupUrlParams = {
  clinicSlug: string;
};

// ClinicLanderLoader is a component that fetches a clinic by its slug and displays a welcome message.
export const ClinicLanderLoader: FunctionComponent = () => {
  const { clinicSlug } = useParams<ClinicLanderCheckupUrlParams>();
  const [searchParams] = useSearchParams();
  const clinicPetIds = searchParams.get(ClinicLanderSearchParams.clinicPetId);
  const clinicMessageId = searchParams.get(ClinicLanderSearchParams.clinicMessageId);

  if (!clinicSlug || clinicSlug === '') {
    return <ErrorAlert title="Clinic Not Found" message="We were unable to find your clinic in our system." />;
  }

  // Create the extraParams map from all the leftover query params, not including the query params defined in
  // ClinicLanderSearchParams.
  const extraParams: Record<string, string> = {};
  for (const [key, value] of searchParams.entries()) {
    if (!Object.keys(ClinicLanderSearchParams).includes(key)) {
      extraParams[key] = value;
    }
  }

  const { clinic, error, isPending } = useClinic(clinicSlug, { clinicPetIds, clinicMessageId }, extraParams);

  return (
    <QueryLoaderWrapper isPending={isPending} error={error}>
      {clinic ? <ClinicLander clinic={clinic} /> : null}
    </QueryLoaderWrapper>
  );
};
