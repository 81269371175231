import React, { FunctionComponent } from 'react';
import landerImage from '../../assets/lander-image.jpg';

interface IntroLanderProps {
  clinicName: string;
  dogName?: string;
  clinicLogoUrl?: string;
  children: React.ReactNode;
}

export const IntroLander: FunctionComponent<IntroLanderProps> = ({ clinicName, dogName, clinicLogoUrl, children }) => {
  const displayDogName = dogName ? dogName : 'your dog';
  const topImageUrl = clinicLogoUrl && clinicLogoUrl.length > 5 ? clinicLogoUrl : landerImage;

  return (
    <div className="flex h-full w-full max-w-lg flex-1 flex-col justify-between gap-4 pb-4">
      <div className="flex h-96 flex-col items-center justify-center">
        <img src={topImageUrl} alt="Taking video of your dog" className="max-h-96 object-contain" />
      </div>
      <div className="space-y-4 px-4 leading-6">
        <p>
          The team at <span className="font-semibold">{clinicName}</span> is requesting an in-home gait assessment of
          <span className="font-semibold">&nbsp;{displayDogName}</span>.
        </p>
        <p>Click below for instructions and to get started!</p>
      </div>
      <div className="space-y-2 px-4">{children}</div>
    </div>
  );
};
